import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-nclima-pt/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import ContactsPage from "../components/contacts.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Contactos" description="N CLIMA é Certificada com uma vasta experiência na Instalação, Manutenção, Reparação e Assistência Técnica em Equipamentos de Refrigeração, Ventilação, Aquecimento, Ar Condicionado, Painéis Solares e Bombas de Calor." image={'https://www.nclima.pt' + ogImage} siteUrl={'https://www.nclima.pt' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <strong>N CLIMA</strong><br />
Rua da Divisão, 698<br />
4500-044 Espinho | Portugal<br />
Location: 41.019050, -8.625408<br />
Phone: +351227330940<br />
Email: geral@nclima.pt<br />
    </MdxLayout>
    <div style={{
      maxWidth: 900,
      margin: '0 auto'
    }}>
    <ContactsPage mdxType="ContactsPage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      